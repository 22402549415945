// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1717702443913.4048";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import { useOptionalUser } from "~/utils";
import logo from "public/gsp-network-logo.png";
export const meta = () => [{
  title: "GSP Network GmbH - Feedback zu den Schulungen"
}];
export default function Index() {
  _s();
  const user = useOptionalUser();
  return <main className="relative min-h-screen bg-white flex items-center justify-center">
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div>
          <a href="https://gsp-network.com/">
            <img src={logo} alt="Remix" className="mx-auto mt-16 w-full max-w-[26rem] md:max-w-[30rem] p-10" />
          </a>
          <a href="./">
            <p className="text-center text-4xl font-bold text-[#083d77] p-2">
              Feedback zu unseren Schulungen
            </p>
          </a>
          <p className="text-center text-xl text-[#083d77] p-2">
            Sagen Sie uns Ihre Meinung!
          </p>
          <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center p-4">
            <div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
              <Link to="/feedback" className="flex items-center justify-center border border-transparent bg-[#083d78] px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-[#de782d] sm:px-8">
                Zum Feedback Fragebogen
              </Link>
              {user ? <Link to="/backoffice/dashboard" className="flex items-center justify-center border border-[#083d78] px-4 py-3 text-xs font-light text-[#083d78] shadow-sm hover:text-[#de782d] hover:border-[#de782d] sm:px-8">
                  {user.email} willkommen
                </Link> : <Link to="/login" className="flex items-center justify-center border border-[#083d78] text-xs font-light text-[#083d78] shadow-sm hover:text-[#de782d] hover:border-[#de782d] ">
                  Login
                </Link>}
            </div>
          </div>
        </div>
        <img className="h-full w-full object-cover" src="SimpleBIM-Desktop-Mockups-Software-Screenshots-4-scaled.jpeg" alt="SimpleBIM-Desktop-Mockups-Software-Screenshots" />
      </div>
    </main>;
}
_s(Index, "gqXoXrwy0rMHgaN7Y1noFHoOsVs=", false, function () {
  return [useOptionalUser];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;